.green {
  color: #2ec72e;
}
.red {
  color: #dc1f1f;
}
.blue {
  color: #257dcb;
}
.yellow {
  color: #ffbd00;
}

.action-button {
  font-size: 10px;
  padding: 1px 12px;
}

.green-button {
  color: #2ec72e;
  border-color: #2ec72e;
}
.red-button {
  color: #dc1f1f;
  border-color: #dc1f1f;
}
.blue-button {
  color: #257dcb;
  border-color: #257dcb;
}
.yellow-button {
  color: #ffbd00;
  border-color: #ffbd00;
}

.green-button:hover {
  background-color: #2ec72e;
  color: #fff;
}
.red-button:hover {
  background-color: #dc1f1f;
  color: #fff;
}
.blue-button:hover {
  background-color: #257dcb;
  color: #fff;
}
.yellow-button:hover {
  background-color: #ffbd00;
  color: #fff;
}
