.nav-item {
  cursor: pointer;
}

.custom-icon {
  margin-right: 10px;
  max-width: 18px;
  max-height: 18px;
}

.nav-item:hover .custom-icon {
  filter: brightness(2);
}

.active-icon {
  filter: sepia(1) hue-rotate(169deg) saturate(4) brightness(0.8);
}

.nav-link-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.nav-link:hover {
  background-color: #ffbd00 !important;
}
