.image-gallery-container {
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.image-gallery-item {
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.square-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
  background: #ccc;
}

#ReactSimpleImageViewer {
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.9);
}
