.header-accordion {
  display: flex;
  cursor: pointer;
}

.header-accordion-icon {
  right: 0;
  margin-left: auto;
  align-self: center;
}

.damage-image {
  border-radius: 18px;
  cursor: pointer;
}

.light-text {
  color: #bbb;
}
