.list-group {
  cursor: pointer;
}
.list-group .active {
  background-color: #ffbd00;
  border-color: #ffbd00;
  color: #fff !important;
}

.list-group .unavailable {
  color: #bbb !important;
}
