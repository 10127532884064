td:focus {
  outline: none;
}

.green-button {
  color: #2ec72e;
  border-color: #2ec72e !important;
  margin-right: 15px;
}

.green-button:hover {
  background-color: #2ec72e !important;
  color: #fff;
}