.app-header {
  background-color: #ffbd00 !important;
  border-bottom: 1px solid #f5b400 !important;
}

.dropdown-toggle::after {
  vertical-align: middle;
}

.white-toggler {
  filter: brightness(0) grayscale(1);
  outline: none;
}

.white-toggler:hover {
  filter: brightness(2) grayscale(1);
}

.white-toggler:focus {
  outline: none;
}

.white-nav-text,
.white-nav-link {
  color: #fff !important;
}
.white-nav-link:hover {
  color: #fff6de !important;
}

.btn-white-dropdown {
  color: #fff;
}
.btn-white-dropdown:hover {
  color: rgba(255, 255, 255, 0.75);
}
.btn-white-dropdown:focus {
  box-shadow: none;
}
