body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.search-label {
  width: 100%;
}

.action-button {
  color: #999;
  cursor: pointer;
}

.yellow-bg {
  background-color: #ffbd00;
  border-color: #ffbd00;
}

.login-card {
  border-radius: 14px;
  border-color: #fff;
}

.custom-btn {
  background: none;
  border: 0;
}

.custom-btn:hover,
.custom-btn:active,
.custom-btn:focus {
  background: none !important;
  border: 0 !important;
}

.green-btn {
  color: #3a9c5c;
}

.green-btn:hover {
  color: rgba(58, 156, 92, 0.7);
}

.white-text-btn {
  color: #fff;
}

.white-text-btn:hover {
  color: rgba(255, 255, 255, 0.7);
}

.absolute-back-btn {
  position: absolute;
  padding: 30px 0 0 30px;
}

.white-form label {
  color: #fff;
}

.white-form input {
  /* height: 46px; */
  border: #fff;
}

.white-form .invalid-feedback {
  color: #ee0000;
}

.signup-header-margin {
  margin-top: 100px;
}

@media only screen and (max-width: 576px) {
  .signup-header-margin {
    margin-top: 40px;
  }
}

.form-control:disabled {
  color: #999999;
  background-color: #f2f2f2;
}
