.plan-container {
  margin-bottom: 8px;
  background-color: #f6f6f6;
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
}

.plan-container.active {
  background-color: #ffbd00;
  color: #fff;
}

.plan-title {
  font-size: 3rem;
}
