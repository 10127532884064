.btn-primary-yellow {
  background-color: #ffbd00;
  border-color: #ffbd00;
  color: #fff;
  margin-left: 10px;
}

.btn-primary-gray {
  background-color: gray;
  border-color: gray;
  color: #fff;
  margin-left: 10px;
}


.btn-primary-yellow:hover,
.btn-primary-yellow:active {
  background-color: #fab700;
  border-color: #fab700;
  color: #fff;
}
