.lineless-modal .modal-header,
.lineless-modal .modal-footer {
  border: none;
}
.btn-secondary-light {
  background-color: #ececec;
  border-color: #ececec;
}
.btn-secondary-light:hover,
.btn-secondary-light:active {
  background-color: #dfdfdf;
  border-color: #dfdfdf;
}

.btn-primary-yellow {
  background-color: #ffbd00;
  border-color: #ffbd00;
  color: #fff;
}

.btn-primary-yellow:hover,
.btn-primary-yellow:active {
  background-color: #fab700;
  border-color: #fab700;
  color: #fff;
}
