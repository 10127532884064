//
.custom-form-group {
  position: relative;
}

.form-message {
  top: 0;
  right: 0;
  text-align: right;
  position: absolute;
}

.show-error {
  display: block !important;
}

.form-message-padded {
  top: 0;
  right: 0;
  padding-right: 15px;
  text-align: right;
  position: absolute;
  display: block;
}

textarea {
  resize: none !important;
}

.is-invalid {
  background-image: none !important;
}
